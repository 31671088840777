import Vue from 'vue';

Vue.filter('formatPrice', value => {
    let val = (value / 1).toFixed(2).replace('.', ',')
    return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
});
Vue.filter('formatDate', value => {
    if (!value) {
        return '-'
    }
    if (value.split("-").length == 1) {
        return value
    }
    let [year, month, day] = value.split("-");
    return `${day}/${month}/${year}`;
});
Vue.filter('formatCnpjCpf', value => {
    if (value.length > 11) {
        value = value.replace(
            /^([0-9]{2})([0-9]{3})([0-9]{3})([0-9]{4})([0-9]{2})$/,
            "$1.$2.$3/$4-$5"
        );
    } else {
        value = value.replace(
            /^([0-9]{3})([0-9]{3})([0-9]{3})([0-9]{2})$/,
            "$1.$2.$3-$4"
        );
    }
    return value;
});
Vue.filter('formatPhones', value => {
    let formatedNumber
    if (value.length < 11) {
        const ddd = value.slice(0, 2);
        const prfix = value.slice(2, 6);
        const sufix = value.slice(6, 10);
        formatedNumber = `(${ddd}) ${prfix}-${sufix}`

    } else {
        const ddd = value.slice(0, 2);
        const extNum = value.slice(2, 3);
        const prfix = value.slice(3, 7);
        const sufix = value.slice(7, 11);
        formatedNumber = `(${ddd}) ${extNum} ${prfix}-${sufix}`
    }
    return formatedNumber;
});