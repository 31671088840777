<template>
    <v-container class="fill-height px-12">
        <v-responsive class="align-start text-center fill-height pa-1">
            <div v-if="initContracts.length < 1 ? false : true"
                style="position: absolute; right: 0; min-width: 420px; min-height: 180px; border-left: 1px solid #ccc;"
                class="d-flex pl-8 mt-7 pb-3 pt-2">
                <v-tooltip left>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn @click="downloadPdf(initContracts[0].id)" v-bind="attrs" v-on="on" color="primary"
                            style="top: 55px; box-shadow: 2px 3px 5px -2px rgba(0, 0, 0, 0.5);" fab absolute large dark
                            top right class="v-btn--example mr-1">
                            <v-icon>cloud_download</v-icon>
                        </v-btn>
                    </template>
                    <span>Download em PDF</span>
                </v-tooltip>
                <div v-if="initContracts.length ? true : false" style="width: 100%;"
                    class="d-flex flex-column flex-wrap">
                    <div class="d-flex align-center justify-start mb-3">
                        <h6 style="font-size: 18px; color: #6c6c6c;" class="text-left font-weight-medium">
                            Contrato inicial -></h6>
                        <span class="ml-3 text-body-2">{{ initContracts[0].company.name }}</span>
                    </div>
                    <v-row>
                        <v-col cols="12" class="mt-2 text-left">
                            <v-avatar class="mr-3" color="blue lighten-5" rounded size="28"><v-icon color="primaryNew"
                                    size="20">{{
                                    setStatusIcon(initContracts[0].status)[0] }}</v-icon></v-avatar>
                            <span class="text-subtitle-2 primaryNew--text">{{ setStatusIcon(initContracts[0].status)[2]
                                }}</span>
                        </v-col>
                    </v-row>

                    <v-row class="mt-0">
                        <v-col cols="12" class="mt-0 text-left">
                            <v-avatar class="mr-3" color="green lighten-5" rounded size="28"><v-icon color="success"
                                    size="20">event_available</v-icon></v-avatar>
                            <span class="text-subtitle-2 success--text">Data da adesão: {{
                                initContracts[0].accession_date | formatDate }}</span>
                        </v-col>
                    </v-row>

                    <v-row class="mt-0">
                        <v-col cols="12" class="mt-0 text-left">
                            <v-avatar class="mr-3" color="orange lighten-5" rounded size="28"><v-icon color="warning"
                                    size="20">event</v-icon></v-avatar>
                            <span class="text-subtitle-2 warning--text">Data da vencimento: {{
                                initContracts[0].validate_date | formatDate }}</span>
                        </v-col>
                    </v-row>
                </div>
            </div>
            <v-row class="mt-1 mb-2 d-flex align-start justify-space-between">
                <v-col cols="12">
                    <h4 class="titlePages text-left font-weight-medium mb-0">Contratos
                    </h4>
                </v-col>
                <v-col cols="12" md="4" class="pl-2 pr-3 mb-3">
                    <v-text-field prepend-icon="filter_alt" append-icon="mdi-magnify" v-model="search"
                        label="Filtrar contratos"></v-text-field>
                </v-col>
                <v-col cols="12" class="mt-15">
                    <v-data-table class="contractTable" :itemsPerPage="5" :headers="headers" :items="contracts"
                        :search="search">
                        <template v-slot:item.status="{ item }">
                            <v-chip class="" :color="setStatusIcon(item.status)[1]" text-color="white">
                                <v-avatar left>
                                    <v-icon>{{ setStatusIcon(item.status)[0] }}</v-icon>
                                </v-avatar>
                                <span style="padding-bottom: 1px;" class="pl-1 pr-2">{{ setStatusIcon(item.status)[2]
                                    }}</span>
                            </v-chip>
                        </template>
                        <template v-slot:item.company.name="{ item }">
                            <div class="text-subtitle-1 font-weight-medium">
                                {{ item.company.name }}
                            </div>
                            <!-- <div class="">
                                Tipo de contrato: <span class="font-weight-medium">
                                    {{ item.contract_type }}
                                </span>
                            </div> -->
                        </template>
                        <template v-slot:item.service.name="{ item }">
                            <div class="text-subtitle-1 font-weight-medium pt-7">
                                {{ item.service.name }}
                            </div>
                            <div class="pb-7">
                                {{ item.service.description }}
                            </div>
                        </template>
                        <template v-slot:item.accession_date="{ item }">
                            <div class="text-subtitle-2 font-weight-medium">
                                Adesão: <span class="text-subtitle-1 success--text font-weight-regular">
                                    {{ item.accession_date | formatDate }}
                                </span>
                            </div>
                            <div class="text-subtitle-2 font-weight-medium">
                                Vencimento: <span class="text-subtitle-1 font-weight-regular warning--text">
                                    {{ item.validate_date | formatDate }}
                                </span>
                            </div>
                        </template>
                        <template v-slot:item.value="{ item }">
                            <div class="title success--text font-weight-regular">
                                R$ {{ item.value | formatPrice }}
                            </div>
                        </template>
                        <template v-slot:item.actions="{ item }">
                            <v-tooltip left>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn @click="downloadPdf(item.id)" v-bind="attrs" v-on="on" small color="primary"
                                        dark fab>
                                        <v-icon size="20">file_download</v-icon>
                                    </v-btn>
                                </template>
                                <span>Download em PDF</span>
                            </v-tooltip>
                        </template>
                        <!-- <template v-slot:item.service="{ item }">
                            <div :class="[setStatusIcon(item.status)[1] + '--text']">{{ item.status }}<v-icon size="21"
                                    class="ml-2" :color="setStatusIcon(item.status)[1]">{{ setStatusIcon(item.status)[0]
                                    }}</v-icon>
                            </div>
                        </template> -->
                    </v-data-table>
                </v-col>
            </v-row>
        </v-responsive>
    </v-container>
</template>

<script>
import ContractsList from "./ContractsList.vue"
import MyFilters from "../../../../filters/genericFilters";
export default {
    methods: {
        setStatusIcon(status) {
            switch (status) {
                case "signed":
                    return ["verified", "green", "Assinado"];
                // case "Reprovado":
                //     return ["remove_circle_outline", "red"];
                // case "Gerando":
                //     return ["history_toggle_off", "primary"];
                default:
                    return ["hourglass_top", "warning", "Aguardando assinatura"];
            }
        },
        getContracts(nLoad) {
            var obj = {
                noLoader: nLoad == "nLoad" ? true : false,
                url: "/api/v1/subscriptionAPI/contract/partners/get-all",
                query: null,
                method: "get",
            };
            this.$store.dispatch("request/doRequest", obj).then((response) => {
                this.contracts = [
                    ...response.data.filter(
                        (contract) => contract.contract_type != "Company contract"
                    ),
                ];
                this.initContracts = [
                    ...response.data.filter(
                        (contract) => contract.contract_type == "Company contract"
                    ),
                ];
            });
        },
        downloadPdf(id) {
            let obj = {
                url: "/api/contract/getContractPdf",
                query: { contract_id: id },
                method: "post",
            };
            this.$store.dispatch("request/doRequest", obj).then((response) => {
                console.log(response.data);
                window.open(response.data.url, "_blank");
            });
        },
    },
    data() {
        return {
            contracts: [],
            initContracts: [],
            logoIcon: require("@/assets/newArea/logo-icon.png"),
            search: "",
            headers: [
                {
                    text: 'Status do contrato',
                    align: 'start',
                    filterable: false,
                    sortable: true,
                    value: 'status',
                },
                {
                    text: 'Empresa',
                    sortable: true,
                    align: 'start',
                    filterable: true,
                    value: 'company.name',
                },
                {
                    text: 'Serviço',
                    sortable: true,
                    align: 'start',
                    filterable: true,
                    value: 'service.name',
                },
                {
                    text: 'Datas',
                    align: 'start',
                    sortable: false,
                    filterable: false,
                    value: 'accession_date',
                },
                { text: 'Valor do contrato', sortable: false, align: 'center', value: 'value' },
                { text: 'Ações', align: 'center', sortable: false, value: 'actions' },

            ],
        }
    },
    mounted() {
        this.getContracts();
    },
    components: {
        ContractsList,
    },
}
</script>

<style>
.contractTable tr td {
    border-bottom: 10px solid #5a78b315 !important;
}

.contractTable tr th {
    background-color: #5a78b315 !important;
}
</style>